import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { Apollo, MutationResult, QueryRef } from 'apollo-angular';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  AcceptEventInvitationGQL,
  AddPlayerToEventGQL,
  CheckIfCityChannelExistsGQL,
  CheckIfCityChannelExistsQuery,
  CreateEventGQL,
  CreateEventMutation,
  CreateFieldGQL,
  CreateTemplateEventGQL,
  DeclineEventInvitationGQL,
  Event,
  EventPlayerChangesDocument,
  FieldType,
  GetAllEventsGQL,
  GetAllEventsQuery,
  GetAllFieldsDocument,
  GetAllFieldsGQL,
  GetAllFieldsQuery,
  GetEventDetailAuthorizedGQL,
  GetEventDetailAuthorizedQuery,
  GetEventDetailUnauthorizedGQL,
  GetEventDetailUnauthorizedQuery,
  GetEventTemplateCountGQL,
  GetEventTemplateCountQuery,
  GetEventTemplateGQL,
  GetEventTemplateQuery,
  GetMyEventsGQL,
  GetMyEventsQuery,
  GetPlayerListGQL,
  JoinEventGQL,
  LeaveEventGQL,
  RemoveEventGQL,
  RemoveEventMutation,
  RemovePlayerToEventGQL,
  ToggleStatusEventGQL,
  UpdateEventGQL,
  UpdateEventMutation
} from 'src/app/services/hobbyts.service';
import { IAddRemovePlayerEventInput } from 'src/app/shared/model/addremoveplayer.interface';
import { errorHandler, queryResponseHandler } from 'src/app/utils/handlers.utils';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(
    private getEventDetailAuthorizedGql: GetEventDetailAuthorizedGQL,
    private getEventDetailUnauthorizedGql: GetEventDetailUnauthorizedGQL,
    private joinEventGQL: JoinEventGQL,
    private leaveEventGQL: LeaveEventGQL,
    private acceptEventInvitationGQL: AcceptEventInvitationGQL,
    private declineEventInvitationGQL: DeclineEventInvitationGQL,
    private addPlayerGQL: AddPlayerToEventGQL,
    private removePlayerGQL: RemovePlayerToEventGQL,
    private removeEventGQL: RemoveEventGQL,
    private apollo: Apollo,
    private getPlayerListGQL: GetPlayerListGQL,
    private getAllFieldsGQL: GetAllFieldsGQL,
    private createFieldGQL: CreateFieldGQL,
    private toggleStatusEventGQL: ToggleStatusEventGQL,
    private createEventGQL: CreateEventGQL,
    private updateEventGql: UpdateEventGQL,
    private checkIfCityChannelExistsGQL: CheckIfCityChannelExistsGQL,
    private createTemplateGql: CreateTemplateEventGQL,
    private getEventTemplateGQL: GetEventTemplateGQL,
    private getEventTemplateCountGQL: GetEventTemplateCountGQL,
    private getAllEventsGQL: GetAllEventsGQL,
    private getMyEventsGQL: GetMyEventsGQL
  ) { }

  public getPlayerList(eventId: string): QueryRef<any, { eventId: string }> {
    return this.getPlayerListGQL.watch({
      eventId
    });
  }

  public subscribeToMore(queryRef: QueryRef<any, { eventId: string }>, eventId: string): void {
    queryRef.subscribeToMore({
      document: EventPlayerChangesDocument,
      variables: {
        eventId
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }
        const data = (subscriptionData.data as any).eventPlayerChanges;
        const newUser = data.user;
        newUser.eventId = data.eventId;
        newUser.playerId = newUser.id;

        if (data.deleted) {
          const updatedPlayerList = prev.getPlayerList.filter((player: any) => player.playerId !== newUser.id);
          return {
            getPlayerList: updatedPlayerList
          };
        } else {
          const playerExists = prev.getPlayerList.some((player: any) => player.playerId === newUser.id);
          if (playerExists) {
            return prev;
          } else {
            return {
              getPlayerList: [...prev.getPlayerList, newUser]
            };
          }
        }
      }
    });
  }

  // Event details already has data for players. In this case players subscription would receive any changes to its list
  public eventDetailsAuthorized(eventId: string): Observable<GetEventDetailAuthorizedQuery | null> {
    return this.getEventDetailAuthorizedGql
      .fetch(
        {
          getEventDetailInput: {
            id: eventId
          }
        },
        { fetchPolicy: 'no-cache' }
      ).pipe(
        map((res) => queryResponseHandler<GetEventDetailAuthorizedQuery>(res)),
        catchError((err) => errorHandler(err))
      );
  }

  public eventDetailsUnauthorized(eventId: string): Observable<GetEventDetailUnauthorizedQuery | null> {
    return this.getEventDetailUnauthorizedGql
      .watch(
        {
          getEventDetailInput: {
            id: eventId
          }
        },
        { fetchPolicy: 'no-cache' }
      )
      .valueChanges.pipe(
        map((res) => queryResponseHandler<GetEventDetailUnauthorizedQuery>(res)),
        catchError((err) => errorHandler(err))
      );
  }

  public addPlayerToEventDetail(addPlayerEventInput: IAddRemovePlayerEventInput): Observable<MutationResult<unknown>> {
    return this.apollo.mutate({
      mutation: this.addPlayerGQL.document,
      variables: {
        addPlayerEventInput
      }
    });
  }

  public removePlayerToEventDetail(
    addPlayerEventInput: IAddRemovePlayerEventInput
  ): Observable<MutationResult<unknown>> {
    return this.apollo.mutate({
      mutation: this.removePlayerGQL.document,
      variables: {
        addPlayerEventInput
      }
    });
  }

  public joinEvent(eventId: string): Observable<MutationResult<unknown>> {
    return this.apollo.mutate({
      mutation: this.joinEventGQL.document,
      variables: {
        joinEventInput: {
          eventId
        }
      }
    });
  }

  public leaveEvent(eventId: string): Observable<MutationResult<unknown>> {
    return this.apollo.mutate({
      mutation: this.leaveEventGQL.document,
      variables: {
        leaveEventInput: {
          eventId
        }
      }
    });
  }

  public removeEvent(eventId: string): Observable<MutationResult<RemoveEventMutation>> {
    return this.apollo.mutate<RemoveEventMutation>({
      mutation: this.removeEventGQL.document,
      variables: { eventId }
    })
  }

  public getAllFields(city: string): Observable<GetAllFieldsQuery | null> {
    return this.getAllFieldsGQL.fetch({ city }).pipe(
      map((res) => queryResponseHandler<GetAllFieldsQuery>(res)),
      catchError((err) => errorHandler(err))
    );
  }

  public createField(newField: FieldType, city: string): Observable<MutationResult<unknown>> {
    return this.apollo.mutate({
      mutation: this.createFieldGQL.document,
      variables: { newField },
      update: (store, { data }: any) => {
        const fields = store.readQuery({
          query: GetAllFieldsDocument,
          variables: { city }
        }) as any;
        store.writeQuery({
          query: GetAllFieldsDocument,
          variables: { city },
          data: {
            getAllFields: [...fields.getAllFields, data.createField]
          }
        });
      }
    })
  }

  public acceptEventInvitation(eventId: string): Observable<MutationResult<unknown>> {
    return this.apollo.mutate({
      mutation: this.acceptEventInvitationGQL.document,
      variables: {
        eventId
      }
    });
  }

  public declineEventInvitation(eventId: string): Observable<MutationResult<unknown>> {
    return this.apollo.mutate({
      mutation: this.declineEventInvitationGQL.document,
      variables: {
        eventId
      }
    });
  }

  public toggleStatusEvent(eventInput: Event, isFull: boolean): Observable<MutationResult<unknown>> {
    return this.apollo.mutate({
      mutation: this.toggleStatusEventGQL.document,
      variables: {
        eventInput,
        isFull
      }
    })
  }

  public createEvent(objToSave: Event, isPostToTG: boolean): Observable<MutationResult<CreateEventMutation>> {
    return this.apollo
      .mutate<CreateEventMutation>({
        mutation: this.createEventGQL.document,
        variables: {
          createEventInput: objToSave,
          isPostToTG
        }
      })
  }

  public editEvent(objToSave: Event, eventId: string | null): Observable<MutationResult<UpdateEventMutation>> {
    return this.apollo
      .mutate<UpdateEventMutation>({
        mutation: this.updateEventGql.document,
        variables: {
          updateEventInput: Object.assign(objToSave, { id: eventId })
        }
      })
  }

  public checkIfCityChannelExists(city: string): Observable<CheckIfCityChannelExistsQuery | null> {
    return this.checkIfCityChannelExistsGQL.fetch(
      { city }
    ).pipe(
      map((res) => queryResponseHandler<CheckIfCityChannelExistsQuery>(res)),
      catchError((err) => errorHandler(err))
    );
  }

  public getEventTemplateCount(): Observable<ApolloQueryResult<GetEventTemplateCountQuery>> {
    return this.getEventTemplateCountGQL.fetch({}, { fetchPolicy: 'no-cache' });
  }

  public getEventTemplate(): Observable<ApolloQueryResult<GetEventTemplateQuery>> {
    return this.getEventTemplateGQL.watch({}, { fetchPolicy: 'no-cache' }).valueChanges;
  }

  public createTemplate(createTemplate: { templateData: string; hobbyTypeId: string; }): Observable<MutationResult<unknown>> {
    return this.apollo
      .mutate({
        mutation: this.createTemplateGql.document,
        variables: {
          createTemplate
        }
      })
  }

  public getAllEvents(paginationInputType: any): Observable<ApolloQueryResult<GetAllEventsQuery>> {
    return this.getAllEventsGQL
      .watch(paginationInputType, { fetchPolicy: 'no-cache' })
      .valueChanges;
  }

  public getMyEvents(paginationInputType: any): Observable<ApolloQueryResult<GetMyEventsQuery>> {
    return this.getMyEventsGQL.watch(paginationInputType).valueChanges;
  }
}
